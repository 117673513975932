import React, { useEffect, useState, useCallback, useRef } from "react";
import Meta from "../components/Meta";
import { Link } from "react-router-dom";
import VideoJS from "../components/VideoPlayer";
import { TagAds, BannerAds, BannerAllAds, GridAds } from "../components/AdsComponent";
import { LazyImage, placeholder, errorPlaceholder } from "../components/LazyImage";
import ErrorRetry, { LoadingText } from "../components/ErrorRetry";
import axios from "axios";

const DetailVideo = ({ data, relation }) => {
  const { title, litpic, playurl, typename, tag, body } = data;
  const [fullplayurl, setFullPlayUrl] = useState(null);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const playerRef = useRef(null);
  const [fileId, setFileId] = useState("");

  /** 获取播放地址 */
  const fetchUrlsAndTest = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      const { data: response } = await axios.get("/c.json");
      const urlPrefixes = response.playurl;

      if (!Array.isArray(urlPrefixes) || urlPrefixes.length === 0) {
        throw new Error("No valid URLs found");
      }

      setTags(tag ? tag.split(",").map((t) => t.trim()) : []);
      const randomIndex = Math.floor(Math.random() * urlPrefixes.length);
      const selectedUrl = urlPrefixes[randomIndex] + playurl;
      setFullPlayUrl(selectedUrl);

      const slashIndex = playurl.indexOf('/');
      const extractedFileId = playurl.substring(0, slashIndex);
      setFileId(`https://8xiy4.xyz/assets/${extractedFileId}.mp4`);

    } catch (e) {
      console.error("Error fetching Play URL prefixes:", e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [playurl]);

  useEffect(() => {
    fetchUrlsAndTest();

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [fetchUrlsAndTest]);

  /** Video.js 配置 */
  const videoJsOptions = fullplayurl
    ? {
        autoplay: false,
        controls: true,
        responsive: true,
        poster: litpic,
        fluid: true,
        sources: [{ src: fullplayurl, type: "application/x-mpegURL" }],
      }
    : null;

  /** 处理 Video.js Player 初始化 */
  const handlePlayerReady = (player) => {
    if (playerRef.current) {
      playerRef.current.dispose();
    }
    playerRef.current = player;

    player.on("error", () => {
      if (player.error()) {
        console.error("Player error:", player.error().message);
      }
      if (window.ym) {
        window.ym(98611300, "reachGoal", "643722222");
      } else {
        console.warn("Yandex Metrica is not initialized.");
      }
    });
  };

  if (loading) return <LoadingText />;
  if (error) return <ErrorRetry title="视频" onClick={fetchUrlsAndTest} />;

  return (
    <>
      <Meta
        title={`${title || "精彩视频"}-8x8x`}
        description={`在线观看视频-${title || "精彩视频"}`}
        keywords={`8x8x,${title || "精彩视频"},${tag || ""}`}
      />
      <BannerAds />
      <GridAds />

      {/* 面包屑导航 */}
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4">
        <Link to="/">首页</Link> &gt; <Link to={`/h/${typename}`}>{typename}</Link>
      </div>

      {/* 视频播放器 */}
      {videoJsOptions && <VideoJS key={fullplayurl} options={videoJsOptions} onReady={handlePlayerReady} />}
      {!videoJsOptions && <div className="max-w-[640px] mx-auto">抱歉，视频已下架</div>}

      {/* 标题 & 详情 */}
      <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">{title}</div>
      {body && <div className="w-full">{body}</div>}

      {/* 相关标签 */}
      <div>
        <div className="px-2 my-4 tags">相关标签</div>
        {tags.map((t, index) => (
          <Link key={index} to={`/tags/${t}/`} className="tags">
            {t}
          </Link>
        ))}
        <TagAds />
      </div>

      {/* 下载按钮 */}
      <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-1 xs:gap-1 xs:text-xs s:text-sm" style={{ marginTop: "8px" }}>
        <a href={fileId} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">
          下载本片
        </a>
        <a href="https://mjv81xw.com" className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">
          地址找回
        </a>
      </div>

      <BannerAllAds />

      {/* 推荐视频 */}
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{ marginTop: "8px" }}>
        推荐视频
      </div>
      <div className="content grid grid-cols-2 xs:grid-cols-1 s:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 mb-4 min-h-[720px]">
        {relation.map((item) => (
          <div key={item.id} className="break-inside-avoid mb-4">
            <Link to={`/${item.id}/`} className="break-inside-avoid mb-4">
              <LazyImage
                className="w-full h-auto object-cover"
                src={item.litpic}
                alt={item.title}
                placeholder={placeholder(380, 235)}
                errorPlaceholder={errorPlaceholder(380, 235, item.title)}
                width={380}
                height={235}
              />
              <p className="text-center mt-2 text-sm line-clamp-3" style={{ marginBottom: "3px", display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis" }}>
                {item.title}
              </p>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default DetailVideo;
