import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer>
    <div className="w-full p-6 text-center border-t border-double border-tb" >
      <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-1 xs:gap-1 xs:text-xs s:text-sm">
        <Link to="tags" className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">所有标签</Link>
      </div>
      <Link to="/">
      8x8x拔插拔插 @2025 警告︰本網站只這合十八歲或以上人士觀看。內容可能令人反感；不可將本網站的內容派發、傳閱、出售、出租、交給或借予年齡未滿18歲的人士或將本網站內容向該人士出示、播放或放映。 LEGAL DISCLAIMERWARNING: THISFORUM CONTAINS MATERIAL WHICH MAY OFFEND AND MAY NOT BE DISTRIBUTED, CIRCULATED, SOLD, HIRED, GIVEN,LENT,SHOWN,PLAYED OR PROJECTED TO A PERSON UNDER THE AGE OF 18 YEARS.站点申明：我们立足于美利坚合众国，受北美法律保护,未满18岁或被误导来到这里，请立即离开！
      </Link>  
    </div>
    <div style={{marginTop:"25px"}}></div>
  </footer>
);

export default Footer;
