import React, { useEffect, useState, useMemo, useCallback } from "react";
import Meta from "../components/Meta";
import { Link } from "react-router-dom";
import axios from "axios";
import { NativeAds, BannerAds, GridAds } from "../components/AdsComponent";
import { LazyImage, placeholder, errorPlaceholder } from "../components/LazyImage";
import ErrorRetry, { LoadingText } from "../components/ErrorRetry";

const Home = () => {
  // 统一管理数据
  const [data, setData] = useState({
    rdlist: [],
    categories: [[], [], [], [], []], // 5个类别
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  /** 插入广告 */
  const insertAdIntoData = useCallback((items) => {
    if (!items.length) return items;
    const randomIndex = Math.floor(Math.random() * (items.length + 1));
    return [...items.slice(0, randomIndex), { title: "itemAd" }, ...items.slice(randomIndex)];
  }, []);

  /** 获取数据 */
  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(false);

    try {
      await new Promise((resolve) => setTimeout(resolve, 50));

      const [rdlistRes, homeRes] = await Promise.all([
        axios.get("/rdlist"),
        axios.get("/home"),
      ]);

      const rdlistData = rdlistRes.data.status === 200 ? rdlistRes.data.data : [];
      const categoriesData =
        homeRes.data.status === 200
          ? homeRes.data.data.map((item, index) => item[`result${index + 1}`] || [])
          : [[], [], [], [], []];

      setData({ rdlist: rdlistData, categories: categoriesData });
    } catch (error) {
      console.error("Error fetching Home data:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  /** 重新获取 `rdlist` 数据 */
  const refreshRdlist = useCallback(async () => {
    try {
      const responseRdlist = await axios.get("/rdlist");
      if (responseRdlist.data.status === 200) {
        setData((prev) => ({ ...prev, rdlist: responseRdlist.data.data }));
      }
    } catch (error) {
      console.error("Error refreshing rdlist:", error);
    }
  }, []);

  // 确保 Hooks 在组件最顶层调用
  const rdlistWithAd = useMemo(() => insertAdIntoData(data.rdlist), [data.rdlist, insertAdIntoData]);
  const categoriesWithAd = useMemo(
    () => data.categories.map((category) => insertAdIntoData(category)),
    [data.categories, insertAdIntoData]
  );

  if (loading) return <LoadingText />;
  if (error) return <ErrorRetry title={"首页更新"} onClick={fetchData} />;

  return (
    <>
      <Meta title="首页-8X8X" description="欢迎来到8x8x，这里有最精彩的在线视频，每天更新永久免费" keywords="3B, 首页, 在线视频, 每天更新, 永久免费" />
      <BannerAds />
      <GridAds />

      {/* 精选视频 */}
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4 flex justify-between items-center" style={{ marginTop: "10px" }} onClick={refreshRdlist}>
        精选视频 <span style={{ marginLeft: "20px" }}>换一批视频</span>
      </div>
      <VideoGrid data={rdlistWithAd} />

      {/* 其他分类 */}
      {["大陆", "日韩", "欧美", "动漫", "三级"].map((category, index) => (
        <div key={index}>
          <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{ marginTop: "10px" }}>
            <Link to={`/h/${category}/`}>{category}</Link> 最新上架
          </div>
          <VideoGrid data={categoriesWithAd[index]} />
        </div>
      ))}
    </>
  );
};

/** 抽离 `VideoGrid` 组件，提高复用性 */
const VideoGrid = React.memo(({ data }) => {
  return (
    <div className="content grid grid-cols-2 xs:grid-cols-1 s:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 mb-4 min-h-[720px]">
      {data.map((item, index) => (
        <div key={index} className="break-inside-avoid mb-2">
          {item.title === "itemAd" ? (
            <NativeAds />
          ) : (
            <Link to={`/${item.id}/`} className="block">
              <LazyImage
                className="w-full h-auto object-cover"
                src={item.litpic}
                alt={item.title}
                placeholder={placeholder(380, 235)}
                errorPlaceholder={errorPlaceholder(380, 235, item.title)}
                width={380}
                height={235}
              />
              <p className="text-center mt-2 text-sm line-clamp-3" style={{ marginBottom: "3px", display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis" }}>
                {item.title}
              </p>
            </Link>
          )}
        </div>
      ))}
    </div>
  );
});

export default Home;
