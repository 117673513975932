import React from 'react';

const AppPage = () => (
  <div class="fixed bottom-0 start-0 z-50 flex justify-between w-full p-6 border-t border-t-tb bg-sc">
            <div class="flex items-center mx-auto">
                <p class="flex items-center text-sm font-normal" style={{"color":"black"}} >
                    <span>APP 
                    <a href="/dsload.html"  class="inline font-medium  underline underline-offset-2 decoration-500 decoration-solid hover:no-underline"> 请下载使用</a>（仅安卓用户）</span>
                </p>
            </div>
        </div>
);

export default AppPage;
