import { useNavigate } from 'react-router-dom';

const NoDetail = ({ message }) => {
    const navigate = useNavigate();
  
    return (
        <div className="h-screen flex flex-col justify-center items-center">
          <h1 className="text-8xl font-bold text-gray-800 my-8">404</h1>
          <p className="text-4xl font-medium text-gray-800">{message}</p>
          <button className="w-28 mx-auto flex items-center justify-center rounded-md border px-4 py-2 text-base font-bold mt-6" onClick={() => navigate(-1)}>点击返回</button>
        </div>
    );
};

export default NoDetail;