import React from 'react';
import { Link } from 'react-router-dom';
import { LazyImage, placeholder, errorPlaceholder } from './LazyImage';

const ThumberList = ({ data }) => {
  return (
    <div className="content grid grid-cols-2 xs:grid-cols-1 s:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-2 mb-4 min-h-[720px]">
        {data.map((item) => (
          <div key={item.id} className="break-inside-avoid mb-4">
            <Link to={`/${item.id}/`} className="break-inside-avoid mb-4">
            {item.litpic && item.litpic.length > 0 ? (
              <>
              <LazyImage
                className="w-full h-auto object-cover"
                src={item.litpic}
                alt={item.title}
                placeholder={placeholder(380, 235)}
                errorPlaceholder={errorPlaceholder(380, 235, item.title)}
                width={380}
                height={235}
              />
                <p className="text-center mt-2 text-sm line-clamp-3" style={{ marginBottom: '3px', display: '-webkit-box', WebkitLineClamp: 3, WebkitBoxOrient: 'vertical', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {item.title}
              </p>
              </>
              
            ) : (
              <p className="text-center bg-tb my-1 px-3 py-5 text-sm">{item.title}</p>
            )}
            </Link>
          </div>
        ))}
      </div>
  );
};

export default ThumberList;
